.popupContainer {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 600;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
}

.popupBody {
  min-width: 300px;
  min-height: 120px;
  max-height: 80vh;
  background: #ffffff;
  border-radius: 24px;
  z-index: 600;
  position: relative;
  overflow-y: auto;
  box-shadow: 0px 4px 28px rgba(0, 82, 153, 0.13)
}

.disableShadow {
  box-shadow: none;
  overflow: initial;
}
.transparent {
  background: transparent;
}

.transparentBackground {
  background-color: transparent;
}

.blurBackground {
  backdrop-filter: blur(5px);
}

.popupContainerHidden {
  display: none;
}

.closeIcon {
  z-index: 600;
  position: absolute;
  top: 30px;
  right: 32px;
  cursor: pointer;
}
