.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.bodyPopupOpen {
  overflow-y: hidden;
}

mark {
  color: inherit;
  background: transparent;
  border-bottom: 1px solid var(--primatyColor);
}

.darkMode mark {
  border-bottom: 1px solid #FFFFFF;
}

#biographyEditArea .ql-container {
  border: none !important;
  border-radius: 8px;
  height: 155px !important;
}

#biographyEditArea .ql-toolbar {
  position: absolute;
  top: -45px;
  right: 0;
  border: none !important;
}

@media screen and (--maxWidth600) {
  #biographyEditArea .ql-toolbar {
    top: -40px;
    left: -14px;
  }
}

#biographyEditArea .quill  {
  margin-top: 30px;
  border: 1px solid rgba(29, 66, 109, 0.3);
  border-radius: 8px;
}

#biographyEditArea .ql-editor {
  font-size: 16px !important;
  font-family: var(--primaryFont) !important;
  color: var(--primatyColor) !important;
  padding: 14px 26px !important;
}

#biographyEditArea .ql-blank::before {
  font-style: normal !important;
  font-size: 16px !important;
  font-family: var(--primaryFont) !important;
  color: rgba(29, 66, 109, 0.6) !important;
}

.react-pdf__Page__textContent {
  display: none;
}

#cookiescript_badge {
  bottom: 17px !important;
}

.tooltip-container[data-popper-placement*='bottom'] .tooltip-arrow {
  margin-top: -0.3rem !important;
}

/*cookies script changes*/
._CookieScriptReportPageCheckboxLabel input {
  transform: none;
}

._CookieScriptReportPageCheckboxLabel ._CookieScriptReportPageCheckboxText {
  font-size: 16px;
}

._CookieScriptReportPageSaveSettingButton {
  color: #fff;
  background: var(--colorGradient);
  border: none;
  padding: 7px 20px!important;
  height: 40px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 35px;
}

._CookieScriptReportPageTable {
  margin: var(--offsetMedium) 0 var(--offsetLarge) 0 !important;
  min-width: 600px;
}

th._CookieScriptReportPageTableTh {
  width: 20%;
}

th._CookieScriptReportPageTableTh:last-child {
  width: auto;
}

.tableWithMobileScroll {
  padding-bottom: 10px;
  width: 100%;
  overflow: auto;
}

.ql-snow .ql-tooltip {
  z-index: 200;
}
