@import './fonts.css';

html,
body {
  color: var(--primatyColor);
  background-color: #FFFFFF;
  font-family: var(--primaryFont);
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
  color: inherit;
}

* {
  box-sizing: border-box;
  margin: 0;
}

/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video, input {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

* {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

:root {
  /*fonts*/
  --primaryFont: 'Poppins', sans-serif;
  --secondaryFont: 'Rubik', sans-serif;

  /*colors*/
  --greyColor: #DDE3E9;
  --primatyColor: #1D426D;
  --primaryColorMuted: rgba(29, 66, 109, 0.8);
  --primatyColorLight: rgba(29, 66, 109, 0.6);
  --primaryColorDark: rgba(29, 66, 109, 0.3);
  --primaryColorTransparent: rgba(29, 66, 109, 0.1);
  --mutedColor: rgba(255, 255, 255, 0.8);
  --colorGradient: linear-gradient(26deg, #01d6b9, #218fee);
  --colorGradientTranparent: linear-gradient(26deg, rgba(1, 214, 185, 0.7), rgba(33, 143, 238, 0.7));
  --gradientFallBackColor: #3a8ede;
  --colorGradientLight: linear-gradient(26deg, rgba(1, 214, 185, 0.2) -0.41%, rgba(33, 143, 238, 0.2));
  --colorGradienLighter: linear-gradient(270deg, #E9F4FD 0%, #E8F5FC 31.11%, #E7F9F9 73.89%, #E6FBF8 100%);
  --redColor: #FF5656;
  --yellowColor: #FFD130;
  --blueColor: #218FEE;
  --lightBlueColor:  linear-gradient(270deg, #E9F4FD 0%, #E8F5FC 31.11%, #E7F9F9 73.89%, #E6FBF8 100%);

  /*dark mode colors*/
  --mainPageDark: #18385E;
  --textMainDark: #FFFFFF;
  --textSubDark: #8CA8CB;
  --borderDark: #8CA8CB;
  --bgDark: #264E7C;
  --bgDarkBorder: #587eaa;
  --bgDarkTransparent: rgba(38, 78, 124, 0.95);

  /*shadows*/
  --cardShadow: 0px 4px 9px rgb(0 82 153 / 9%);
  --cardShadowMedium: 0px 12px 16px rgb(0 82 153 / 13%);
  --cardShadowLarge: 0px 10px 10px 2px rgb(29 66 109 / 24%);

  /*sizes*/
  --offsetExtraSmall: 8px;
  --offsetSmall: 16px;
  --offsetMedium: 24px;
  --offsetLarge: 32px;
  --offsetExtraLarge: 40px;

  --statusAccept: #01D6B9;
  --statusDecline: #FF5656;
  --statusDraft: #607A99;
}

.darkMode {
  color: var(--textMainDark);
  background-color: var(--mainPageDark);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.mb1 {
  margin-bottom: 4px;
}

.mb2 {
  margin-bottom: 8px;
}

.mb3 {
  margin-bottom: 12px;
}

.mb4 {
  margin-bottom: 16px;
}

/* media queries*/

@custom-media --maxWidth2000 (max-width: 2000px);

@custom-media --maxWidth1300 (max-width: 1300px);

@custom-media --maxWidth1250 (max-width: 1250px);

@custom-media --maxWidth1200 (max-width: 1200px);

@custom-media --maxWidth1100 (max-width: 1100px);

@custom-media --maxWidth1000 (max-width: 1000px);

@custom-media --maxWidth900 (max-width: 900px);

@custom-media --maxWidth850 (max-width: 850px);

@custom-media --maxWidth750 (max-width: 750px);

@custom-media --maxWidth600 (max-width: 600px);

@custom-media --maxWidth460 (max-width: 460px);

@custom-media --maxWidth400 (max-width: 400px);

@custom-media --maxWidth320 (max-width: 320px);

@custom-media --safariBrowser (min-resolution:.001dpcm);
