@font-face {
  font-family: 'Poppins';
  src: url('/fonts/poppins/light/Poppins-Light.eot');
  src: url('/fonts/poppins/light/Poppins-Light.eot?#iefix') format('embedded-opentype'),
    url('/fonts/poppins/light/Poppins-Light.svg#Poppins-Light') format('svg'),
    url('/fonts/poppins/light/Poppins-Light.ttf') format('truetype'),
    url('/fonts/poppins/light/Poppins-Light.woff') format('woff'),
    url('/fonts/poppins/light/Poppins-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('/fonts/poppins/medium/Poppins-Medium.eot');
  src: url('/fonts/poppins/medium/Poppins-Medium.eot?#iefix') format('embedded-opentype'),
    url('/fonts/poppins/medium/Poppins-Medium.svg#Poppins-Medium') format('svg'),
    url('/fonts/poppins/medium/Poppins-Medium.ttf') format('truetype'),
    url('/fonts/poppins/medium/Poppins-Medium.woff') format('woff'),
    url('/fonts/poppins/medium/Poppins-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('/fonts/poppins/regular/Poppins-Regular.eot');
  src: url('/fonts/poppins/regular/Poppins-Regular.eot?#iefix') format('embedded-opentype'),
    url('/fonts/poppins/regular/Poppins-Regular.svg#Poppins-Regular') format('svg'),
    url('/fonts/poppins/regular/Poppins-Regular.ttf') format('truetype'),
    url('/fonts/poppins/regular/Poppins-Regular.woff') format('woff'),
    url('/fonts/poppins/regular/Poppins-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('/fonts/poppins/semiBold/Poppins-SemiBold.eot');
  src: url('/fonts/poppins/semiBold/Poppins-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('/fonts/poppins/semiBold/Poppins-SemiBold.svg#Poppins-SemiBold') format('svg'),
    url('/fonts/poppins/semiBold/Poppins-SemiBold.ttf') format('truetype'),
    url('/fonts/poppins/semiBold/Poppins-SemiBold.woff') format('woff'),
    url('/fonts/poppins/semiBold/Poppins-SemiBold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Rubik';
  src: url('/fonts/rubik/light/Rubik-Light.eot');
  src: url('/fonts/rubik/light/Rubik-Light.eot?#iefix') format('embedded-opentype'),
    url('/fonts/rubik/light/Rubik-Light.svg#Rubik-Light') format('svg'),
    url('/fonts/rubik/light/Rubik-Light.ttf') format('truetype'),
    url('/fonts/rubik/light/Rubik-Light.woff') format('woff'),
    url('/fonts/rubik/light/Rubik-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Rubik';
  src: url('/fonts/rubik/medium/Rubik-Medium.eot');
  src: url('/fonts/rubik/medium/Rubik-Medium.eot?#iefix') format('embedded-opentype'),
    url('/fonts/rubik/medium/Rubik-Medium.svg#Rubik-Medium') format('svg'),
    url('/fonts/rubik/medium/Rubik-Medium.ttf') format('truetype'),
    url('/fonts/rubik/medium/Rubik-Medium.woff') format('woff'),
    url('/fonts/rubik/medium/Rubik-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Rubik';
  src: url('/fonts/rubik/regular/Rubik-Regular.eot');
  src: url('/fonts/rubik/regular/Rubik-Regular.eot?#iefix') format('embedded-opentype'),
    url('/fonts/rubik/regular/Rubik-Regular.svg#Rubik-Regular') format('svg'),
    url('/fonts/rubik/regular/Rubik-Regular.ttf') format('truetype'),
    url('/fonts/rubik/regular/Rubik-Regular.woff') format('woff'),
    url('/fonts/rubik/regular/Rubik-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Rubik';
  src: url('/fonts/rubik/semiBold/Rubik-SemiBold.eot');
  src: url('/fonts/rubik/semiBold/Rubik-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('/fonts/rubik/semiBold/Rubik-SemiBold.svg#Rubik-SemiBold') format('svg'),
    url('/fonts/rubik/semiBold/Rubik-SemiBold.ttf') format('truetype'),
    url('/fonts/rubik/semiBold/Rubik-SemiBold.woff') format('woff'),
    url('/fonts/rubik/semiBold/Rubik-SemiBold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
